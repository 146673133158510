<template>
    <gk-searchbar
        v-model:form="searchForm"
        :config="searchConfig"
        :fold-display-number="3"
        :foldable="true"
        :options="searchOptions"
        @reset="getDataList"
        @search="getDataList"
    />
    <div class="common-list">
        <div class="button-wapper"></div>
        <div class="table-wrapper">
            <el-table :data="dataList" border stripe @selection-change="changeSelectionIds">
                <el-table-column type="selection" width="55" />
                <el-table-column label="ID" prop="id" width="100" />
                <el-table-column label="订单号" prop="order.order_sn" />
                <el-table-column label="产品信息">
                    <template #default="scope">
                        <span v-for="(item, index) in scope.row.order.information">
                            {{ `${Object.keys(item)[0]}:${Object.values(item)[0]}` }}
                            {{ index !== scope.row.order.information.length - 1 ? "、" : "" }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="发货数量" prop="order.number" width="100" />
                <el-table-column label="收货状态">
                    <template #default="scope">{{ scope.row.status === 0 ? "未收货" : "已收货" }}</template>
                </el-table-column>
                <el-table-column label="发货时间" prop="order.delivery_at" />
                <el-table-column label="备注" prop="order.remark" />
                <el-table-column label="操作" width="200">
                    <template #default="scope">
                        <el-button plain size="mini" type="primary" @click="openDetailDialog(scope.row.shipment_id)">
                            发货详情
                        </el-button>
                        <el-button
                            plain
                            size="mini"
                            :disabled="scope.row.status === 1"
                            type="success"
                            @click="onReseive(scope.row.id)"
                        >
                            确认收货
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="pagination-wrapper">
            <gk-pagination
                v-model:currentPage="dataPagination.current"
                v-model:pageSize="dataPagination.size"
                :total="dataPagination.total"
                @change="getDataList"
            />
        </div>
    </div>
    <goods-receive-detail v-model:show="detailDialog.show" :id="detailDialog.id" />
</template>

<script setup>
import { onMounted, ref } from "vue";
import useCommonList from "../../../../hooks/useCommonList";
import GoodsReceiveDetail from "./GoodsReceiveDetail.vue";
import { GET_GOODS_RECEIVE_LIST, GET_GOODS_SEND_LIST, UPDATE_GOODS_RECEIVE_STATUS } from "@/api/goods";
import { ElMessage, ElMessageBox } from "element-plus";

let { dataPagination, dataList, selectionIds, changeSelectionIds } = useCommonList();

onMounted(() => {
    getDataList();
});

// 搜索项
let searchConfig = ref([{ prop: "type", type: "select", label: "收货状态" }]);

let searchOptions = ref({
    type: [
        { label: "待收货", value: "stay" },
        { label: "已收货", value: "have" }
    ]
});

let searchForm = ref({
    type: null
});

// 获取数据
let getDataList = () => {
    GET_GOODS_RECEIVE_LIST({
        ...searchForm.value,
        page: dataPagination.value.current,
        limit: dataPagination.value.size
    }).then((res) => {
        dataList.value = res.data.data.list;
        dataPagination.value.total = res.data.data.mate.total;
        console.log(res.data.data);
    });
};

// 查看详情
let detailDialog = ref({
    show: false,
    id: null
});

let openDetailDialog = (id) => {
    detailDialog.value = {
        show: true,
        id: id
    };
};

// 确认收货
let onReseive = (id) => {
    ElMessageBox.confirm("确认收货？", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning"
    }).then(() => {
        UPDATE_GOODS_RECEIVE_STATUS({ id: id, status: 1 }).then((res) => {
            if (res.data.code === 200) {
                getDataList();
                ElMessage.success("确认收货成功");
            }
        });
    });
};
</script>
